@import "~bootstrap/scss/bootstrap.scss";

$assetPath : "/static";

$base-bg: #fdfcfb;
$base-font: #292827;
$base-link: #ff6c02;
$base-link-hover: #b44b00;

$search-bg: #292827;
//$search-bg: linear-gradient(140deg, #eee 0%, #bbb 100%);
$breadcrumb-bg: linear-gradient(140deg, #eee 0%, #bbb 100%);
$job-summary-bg: linear-gradient(140deg, #222 0%, #444 100%);
$job-described-bg: linear-gradient(120deg, #fff 0%, #ccc 100%);
$job-apply-bg: #222;

$contact-address-bg: linear-gradient(140deg, #222 0%, #444 100%);

$footer-bg: linear-gradient(140deg, #444 0%, #222 100%);
$footer-font: #ddd;

$copyright-bg: #444;
$copyright-font: #bbb;

$toggler-color: #666;
$toggler-color-scrolled: #666;

// gradients
$gradient-deg: 140deg;
$blue-gradient: linear-gradient($gradient-deg, #3482e7 0%, #004091 100%);
$blue-gradient-invert: linear-gradient($gradient-deg, #004091 0%, #3482e7 100%);
$orange-gradient: linear-gradient($gradient-deg, #c54700 0%, #ff6c02 100%);
$orange-gradient-invert: linear-gradient($gradient-deg, #ff6c02 0%, #c54700 100%);
$grey-gradient: linear-gradient($gradient-deg, #505050 0%, #222 100%);
$grey-gradient-invert: linear-gradient($gradient-deg, #222 0%, #505050 100%);
$white-gradient: linear-gradient($gradient-deg, #fff 0%, #ccc 100%);
$white-gradient-invert: linear-gradient($gradient-deg, #ccc 0%, #fff 100%);

body {
  background: $base-bg;
  min-height: 100%;
}

a {
  color: $base-link;
  text-decoration: none;

  &:hover {
    color: $base-link-hover;
    transition-duration: 0.5s;
  }
}

p.justified {
  text-align: justify;
}

div#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;

  div#menu {
    position: fixed;
    text-transform: uppercase;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
    transition-property: background-color + height + opacity;
    transition-duration: 0.5s;
    opacity: 1;

    nav {
      button.navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid $toggler-color;
        border-radius: 0.25rem;
        transition: box-shadow 0.15s ease-in-out;

        //span.navbar-toggler-icon {}
        .navbar-toggler-icon {
          background-color: $toggler-color;
          -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
          mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
      }

      a#nav-brand {
        text-decoration: none;
        color: #ff6c02;

        &:hover {
          font-weight: bold;
        }

        img {
          height: auto;
          width: auto;
          max-width: 140px;
          max-height: 140px;
          transform: translate3d(5%, 5%, 0);
        }
      }

      div.navbar-collapse {
        align-items: center;

        a {
          color: $base-link;
          text-decoration: none;
          display: block;
          padding: 10px 25px;
          border-radius: 10px;

          &:hover {
            color: #fff;
            background-color: $base-link;
            transition: 0.4s;
            border: none;
            border-radius: 25px;
          }
        }
      }
    }

    &.nav-scrolled {
      background: #222;
      opacity: 0.9;
      transition-property: background-color + opacity;
      transition-duration: 0.5s;

      nav {
        #nav-brand {
          height: 50px;
          transition-property: height;
          transition-duration: 0.5s;
        }

        button.navbar-toggler {
          border: 1px solid $toggler-color-scrolled;
          .navbar-toggler-icon {
            background-color: $toggler-color-scrolled;
            -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
          }
        }
      }
    }
  }

  .header-job,
  .header-jobs {
    background-image: url("../../vendors/jobs/jobs.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-contact {
    background-image: url("../../vendors/contact/contact.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-about {
    background-image: url("../../vendors/about/about-us.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-add-cv {
    background-image: url("../../vendors/cv/add-cv.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-candidate {
    background-image: url("../../vendors/candidate/for-candidate.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-employer {
    background-image: url("../../vendors/employer/for-employer.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .header-privacy,
  .header-gprd {
    background-image: url("../../vendors/about/about-us.jpg");
    background-position: 50% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    box-sizing: border-box;
  }

  .feature-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
    font-size: 4rem;
    color: #fff;
    border-radius: 0.75rem;
    background-color: $base-link;
  }

  div#header-home {
    color: #fff;
    //background-clip: border-box;
    background-image: url("../../vendors/home/home.jpg");
    //background-color: rgba(0, 0, 0, 10%);
    //background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
    //background-position-y: -120px;
    min-height: 100vh;
    align-items: center;
    box-sizing: border-box;
    text-shadow: #000 3px 0 5px;

    .home-btn {
      background-image: $orange-gradient;
      padding: 15px 10%;
      margin-bottom: 5%;
      font-size: 1.5em;
      border: none;
      border-radius: 15px;

      &:hover {
        background-image: $orange-gradient-invert;
        transition-property: background-image;
        transition-duration: 0.7s;
      }
    }

    h1 {
      margin: auto;
      padding-top: 340px;
      font-size: 3rem;
      width: 80vw;
      text-shadow: #000 3px 0 5px;
    }

    h2 {
      width: 80vw;
      margin: auto;
      padding-top: 50px;
      padding-bottom: 80px;
      font-size: 2rem;
      text-shadow: #000 3px 0 5px;
    }
  }

  div#header-error {
    color: #fff;
    background-image: $white-gradient-invert;
    background-blend-mode: darken;
    background-size: cover;
    min-height: 70vh;
    align-items: center;
    box-sizing: border-box;
    text-shadow: #000 3px 0 5px;

    h1 {
      margin: auto;
      padding-top: 340px;
      font-size: 3rem;
      width: 80vw;
      text-shadow: #000 3px 0 5px;
    }

    h2 {
      width: 80vw;
      margin: auto;
      padding-top: 50px;
      padding-bottom: 80px;
      font-size: 2rem;
      text-shadow: #000 3px 0 5px;
    }
  }

  div#header {
    padding: 0;

    div#title {
      min-height: 350px;
      text-align: center;
      color: #fff;

      h1 {
        width: 80vw;
        margin: auto;
        padding-top: 180px;
        font-size: 46px;
        text-shadow: #000 3px 0 5px;
      }

      h2 {
        padding-top: 50px;
        padding-bottom: 80px;
        font-size: 26px;
        width: 80vw;
        margin: auto;
        text-shadow: #000 3px 0 5px;
      }
    }
  }

  div#breadcrumb {
    background: $breadcrumb-bg;

    nav {
      padding: 10px;
      max-width: 980px;
      //display: block;
      margin-right: auto;
      margin-left: auto;

      ol.breadcrumb {
        margin: 0;
      }
    }
  }

  div#footer {
    font-size: 1em;
    color: $footer-font;
    background: $footer-bg;

    footer {
      max-width: 980px;
      display: block;
      margin-right: auto;
      margin-left: auto;

      .row {
        div {
          max-width: 260px;
          margin: 15px auto;
        }
      }
    }
  }

  div#copyrights {
    //max-width: 1200px;
    //display: block;
    //margin-right: auto;
    //margin-left: auto;
    background-color: $copyright-bg;

    div#copyright {
      font-size: 1em;
      margin: 0 15px;

      div {
        * {
          margin: 0;
          padding: 0;
          color: $copyright-font;
        }
      }
    }
  }

  div#search {
    padding: 0;
    background: $search-bg;

    div#jobs-search {
      margin: 0 auto;
      padding: 0 15px;
      max-width: 980px;
      display: block;
      text-align: center;

      h2 {
        color: #fff;
        padding: 20px;
      }
    }
  }

  div#content {
    margin: 0;
    padding: 0;
    display: block;
    background: $job-described-bg;

    div#about-info {
      color: #fff;
      padding: 1%;
      font-size: 1.2rem;
      line-height: 1.5rem;
      background-image: linear-gradient(140deg, #3482e7 0%, #004091 100%);
      //background-image: linear-gradient(140deg, #222 0%, #505050 100%);

      #about-info-container {
        width: 80vw;
        max-width: 920px;
        padding: 3% 30px;
        margin: 0 auto;
        text-align: justify;

        h2 {
          text-align: center;
          padding: 15px;
          margin-bottom: 1vw;
        }
      }
    }

    div#about-special {
      color: #fff;
      padding: 1%;
      font-size: 1.2rem;
      line-height: 1.5rem;
      background-color: #ff6c02;
      background-image: linear-gradient(140deg, #cc4a00 0%, #ff6c02 100%);

      #about-special-container {
        width: 80vw;
        max-width: 920px;
        padding: 3% 30px;
        margin: 0 auto;
        text-align: justify;

        h2 {
          text-align: center;
          padding: 15px;
          margin-bottom: 1vw;
        }
      }
    }

    div#about-excerpt {
      color: #fff;
      padding: 1%;
      font-size: 1.2rem;
      line-height: 1.5rem;
      background-image: linear-gradient(140deg, #222 0%, #505050 100%);

      #about-excerpt-container {
        width: 80vw;
        max-width: 920px;
        padding: 3% 30px;
        margin: 0 auto;
        text-align: justify;

        h2 {
          text-align: center;
          padding: 15px;
          margin-bottom: 1vw;
        }
      }
    }

    #about-specialist {
      color: #fff;
      padding: 1%;
      font-size: 1.2rem;
      line-height: 1.5rem;
      background-image: linear-gradient(140deg, #505050 0%, #222 100%);

      #about-specialist-container {
        width: 80vw;
        max-width: 920px;
        padding: 3% 30px;
        margin: 0 auto;
        text-align: justify;

        h2 {
          text-align: center;
          padding: 15px;
          margin-bottom: 1vw;
        }
      }
    }

    div#content-jobs-list {
      padding: 20px;
      text-align: center;
      max-width: 980px;
      margin-left: auto;
      margin-right: auto;

      .content-jobs-list-row {
        border-bottom: solid 1px #ff6c02;

        .content-job-link {
          padding-top: 10px;
          padding-bottom: 10px;

          a {
            color: #444;

            .job-list-item {
              display: flex;
              flex-wrap: nowrap;

              .job-list-item-title {
                padding: 5px;
                text-align: left;
                text-wrap: normal;
                font-weight: bold;
              }

              .job-list-item-location {
                padding: 5px;
                text-align: right;
                text-wrap: avoid;
              }

              h3 {
                word-wrap: normal;
              }

              .bi-geo-alt {
                color: $base-link;
              }
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: #fff1e7;
          transition-duration: 0.4s;
        }
      }
    }

    div#jobs-job-summary {
      background: $job-summary-bg;

      div#jobs-job-summary-details {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 980px;
        padding: 25px 15px;
        color: #eee;
        text-align: justify;

        .font-weight-title {
          font-size: 2rem;
          text-align: left;
        }

        div#jobs-job-summary-info {
          margin: auto 0;

          .bi-tag,
          .bi-geo-alt {
            color: $base-link;
          }
        }
      }

      div {
        a.apply-btn {
          background-color: $base-link;
          color: #fff;
          border: none;
          padding: 35px;
          margin: 15px;

          &:hover {
            background-color: $base-link-hover;
            transition-duration: 0.5s;
          }
        }
      }
    }

    div#jobs-job-described {
      background: $job-described-bg;
      padding: 20px;

      div#jobs-job-offer {
        //padding: 20px;
        margin: 20px auto;
        max-width: 980px;
        text-align: justify;
      }

      h2 {
        color: $base-link;
        padding-top: 15px;
        padding-bottom: 5px;
      }
    }

    div#jobs-job-apply {
      background: $job-apply-bg;

      div {
        text-align: center;
        max-width: 920px;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-right: auto;
        margin-left: auto;

        a.apply-btn {
          background-color: $base-link;
          color: #fff;
          border: none;
          padding: 15px 40%;
          margin: 15px;

          &:hover {
            background-color: $base-link-hover;
            transition-duration: 0.5s;
          }
        }
      }
    }

    div#privacy-policy,
    div#gprd-policy {
      max-width: 980px;
      padding: 3% 30px;
      margin-right: auto;
      margin-left: auto;
      text-align: justify;
    }

    div#contact-address {
      text-align: center;
      margin: 0 auto;
      padding: 40px 20px;
      background: $contact-address-bg;
      color: #ddd;
      //font-weight: bold;
      font-size: 1em;

      div#contact-cards {
        margin: 0 auto;
        max-width: 980px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .contact-card {
          min-width: 300px;
          max-width: 400px;
          margin: 15px auto;
        }
      }
    }

    div#contact-form {
      //background-image: url("../../vendors/contact/contact-form.jpg");
      background-position: 50% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      align-items: center;
      box-sizing: border-box;
      padding: 30px 20px;

      h2 {
        padding-top: 20px;
        //color: #fff;
        //text-shadow: #000 3px 0 5px;
      }

      .custom-button {
        background: $base-link;
        border: none;
        padding: 15px 30px;
      }

      form.contact-form-form {
        max-width: 600px;
        padding: 30px 20px;
        margin: 0 auto;
        text-align: center;

        input,
        textarea,
        button {
          margin: 10px;
        }
      }
    }

    div#contact-map {
      //max-width: 980px;
      padding-bottom: 35px;
      //margin: 0 auto;
    }

    div#cv-add {
      padding: 5%;
      background-image: $grey-gradient-invert;

      a.send-cv-btn {
        padding: 15px 10%;
        background-image: $orange-gradient;
        margin-bottom: 5%;
        font-size: 2em;
        border: none;
        border-radius: 15px;

        &:hover {
          background-image: $orange-gradient-invert;
          transition-property: background-image;
          transition-duration: 0.7s;
        }
      }

      #cv-add-text {
        width: 80vw;
        margin: auto;

        p {
          color: #fff;
        }

        blockquote {
          padding: 15px;
          font-style: italic;
          color: #ccc;
          text-align: justify;
          align-items: center;
          justify-content: center;
        }
      }
    }

    div#cv-who {
      font-size: 1.5rem;
      background-color: #ff6c02;
      background-image: $orange-gradient-invert;

      #cv-who-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #fff;

        div#cv-who-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#cv-who-text {
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }

          ul {
            line-height: 3rem;

            li {
              list-style: none;

              .arrow {
                color: #ff9f48;
                margin: 0 5px;
              }
            }
          }
        }
      }
    }

    div#candidate-stages {
      color: #fff;
      background: linear-gradient(140deg, #444 0%, #222 100%);

      a {
        color: #ff6c02;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: #fff;
          transition-duration: 0.5s;
          text-decoration: none;
        }
      }

      #candidate-stages-details {
        padding: 5%;

        h2 {
          font-size: 3rem;
          padding-bottom: 3%;
        }

        #candidate-stages-offer {
          .arrow {
            font-size: 36pt;
            margin: 20px;
            color: #ff6c02;
          }
        }
      }
    }

    div#candidate-interview {
      font-size: 1rem;
      background-color: #ff6c02;
      background-image: linear-gradient(140deg, #cc4a00 0%, #ff6c02 100%);

      #candidate-interview-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #fff;

        div#candidate-interview-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#candidate-interview-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#candidate-support {
      font-size: 1rem;
      //background-image: linear-gradient(120deg, #fff 0%, #ccc 100%);
      //background-color: #fff;

      #candidate-support-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        color: #222;

        div#candidate-support-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#candidate-support-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#candidate-recommendation {
      font-size: 1rem;
      background-color: #ff6c02;
      background-image: linear-gradient(140deg, #cc4a00 0%, #ff6c02 100%);

      #candidate-recommendation-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #fff;

        div#candidate-recommendation-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#candidate-recommendation-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#candidate-preparation {
      font-size: 1rem;
      //background-image: linear-gradient(120deg, #fff 0%, #ccc 100%);
      //background-color: #fff;

      #candidate-preparation-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        color: #222;

        div#candidate-preparation-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#candidate-preparation-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#employer-about {
      color: #fff;
      background-color: #292826;
      background-image: linear-gradient(140deg, #222 0%, #444 100%);
      padding: 10vh 0;
      text-shadow: #000 3px 0 5px;

      .employer-about-btn {
        padding: 15px 10%;
        background-image: $orange-gradient;
        margin-bottom: 5%;
        font-size: 2em;
        border: none;
        border-radius: 15px;

        &:hover {
          background-image: $orange-gradient-invert;
          transition-delay: 0.5s;
        }
      }

      #employer-about-offer {
        display: flex;
        flex-wrap: wrap;
        max-width: 1020px;
        margin: 30px auto;
        justify-content: center;

        .employer-about-offer-short {
          min-width: 300px;
          padding: 10px;
          align-self: flex-end;
        }
      }

      h2 {
        font-size: 2.5rem;
        padding-bottom: 3rem;
        padding-top: 3rem;
      }

      h3 {
        font-size: 1.5rem;
        text-align: center;
      }

      img {
        padding: 15px 10px;
      }

      a.btn {
        text-shadow: none;
        margin: 30px auto;
      }
    }

    div#employer-perm {
      font-size: 1rem;

      #employer-perm-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #000;

        div#employer-perm-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#employer-perm-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#employer-headhunt {
      background-color: #ff6c02;
      background-image: linear-gradient(140deg, #cc4a00 0%, #ff6c02 100%);
      font-size: 1rem;

      #employer-headhunt-container {
        max-width: 920px;
        padding: 3% 10%;
        margin: 0 auto;
        text-align: justify;
        color: #fff;
      }
    }

    div#employer-tests {
      font-size: 1rem;

      #employer-tests-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #000;

        div#employer-tests-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#employer-tests-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#employer-outplacement {
      background-color: #ff6c02;
      background-image: linear-gradient(140deg, #cc4a00 0%, #ff6c02 100%);
      font-size: 1rem;
      color: #fff;

      #employer-outplacement-container {
        max-width: 920px;
        padding: 3% 30px;
        margin: 0 auto;
        text-align: justify;
      }
    }

    div#home-offers {
      color: #fff;
      background-image: $blue-gradient;
      background-color: #292826;
      padding: 5%;
      text-shadow: #000 3px 0 5px;

      .home-offers-btn {
        padding: 15px 10%;
        background-image: $orange-gradient;
        margin-bottom: 5%;
        font-size: 1.5em;
        border: none;
        border-radius: 15px;

        &:hover {
          background-image: $orange-gradient-invert;
          transition-property: background-image;
          transition-duration: 0.7s;
        }
      }

      #home-offers-offer {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        max-width: 920px;
        margin: 30px auto;

        .home-offers-offer-short {
          min-width: 230px;
          margin: auto;
        }
      }

      h2 {
        font-size: 2.5rem;
        padding-bottom: 3rem;
        padding-top: 3rem;
      }

      h3 {
        font-size: 1.6rem;
      }

      a.btn {
        text-shadow: none;
        margin: 30px auto;
      }
    }

    div#home-employers {
      font-size: 1rem;

      .home-more-btn {
        background-image: $blue-gradient;
        padding: 15px 35%;
        margin-bottom: 5%;
        font-size: 1em;
        border: none;
        border-radius: 15px;

        &:hover {
          background-image: $blue-gradient-invert;
          transition-property: background-image;
          transition-duration: 0.7s;
        }
      }

      #home-employers-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: #000;
        //background-image: $grey-gradient;

        div#home-employers-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#home-employers-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }

    div#home-candidates {
      font-size: 1rem;

      .home-more-btn {
        background-image: $blue-gradient;
        padding: 15px 35%;
        margin-bottom: 5%;
        font-size: 1em;
        border: none;
        border-radius: 15px;

        &:hover {
          background-image: $blue-gradient-invert;
          transition-property: background-image;
          transition-duration: 0.7s;
        }
      }

      #home-candidates-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        color: #fff;
        background-image: $orange-gradient-invert;

        div#home-candidates-img {
          display: flex;
          vertical-align: top;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        div#home-candidates-text {
          max-width: 920px;
          padding: 3% 10%;
          text-align: justify;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;

          h2 {
            text-align: center;
            padding: 15px;
            margin-bottom: 1vw;
          }
        }
      }
    }
  }
}
